import * as Api from '@/api'

const GET_APM_URL = '/apm/url_redirect/'
const GET_APM_URLS = '/apm/reporting/'

export default {
  get () {
    return Api.http(true).get(GET_APM_URL)
  },
  list () {
    return Api.http(true).get(GET_APM_URLS)
  }
}
