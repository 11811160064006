<template>
  <md-list class="reporting-md-list">
    <md-list-item v-for="item in list" :key="item.id" @click="item.id === selecteditem.id ? '':  clickMe(item)" :class="item.id === selecteditem.id ? 'selected': ''">{{ item.name }} <md-icon class="sidebar-icon">keyboard_arrow_right</md-icon></md-list-item>
  </md-list>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      require: true
    },
    selecteditem: {
      type: String
    }
  },
  data () {
    return {}
  },
  methods: {
    clickMe (item) {
      this.$emit('selected', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.reporting-md-list {
  font-size: 12px !important;
}
.md-list-item-container {
  font-size: 12px !important;
}
button:focus {
  outline: 0px auto -webkit-focus-ring-color !important;
}
.sidebar-icon {
  color: black !important;
  width: auto !important;
  min-width: auto !important;
}
.md-list-item-content>.md-icon:last-child {
  margin-right: 5px !important;
}
.md-list-item:hover {
  background-color: #0084D6 !important;
  color:#fff !important;
  .md-list-item-content {
    color:#fff !important;
  }
  .md-ripple {
    color:#fff !important;
  }
  .md-list-item-button {
    color:#fff !important;
  }
  .md-icon {
    color:#fff !important;
  }
  span{
    color:#fff !important;
  }
}
.selected {
  background-color: #0084D6 !important;
  color:#fff !important;
  .md-list-item-content {
    color:#fff !important;
  }
  .md-ripple {
    color:#fff !important;
  }
  .md-list-item-button {
    color:#fff !important;
  }
  .md-icon {
    color:#fff !important;
  }
  span{
    color:#fff !important;
  }
}
</style>
