<template>
  <div id="apm-dashboard">
    <div v-if="list.length > 1 && hide === false" class="apm-dashboard-sidebar">
      <sidebar :list="list" :selecteditem="item" @selected="selected($event)" />
    </div>
    <div style="width:100%;">
      <div style="width:100%;padding: 10px;">
        <md-content class="md-elevation-1">
          <span class="md-subheading" style="float: left; padding: 10px;">Filters</span>
          <div style="display: flex; padding: 10px; width: 100%;">
            <div style="width: 200px; padding-right: 10px">
              <md-field>
                <label for="filter">Filter</label>
                <md-select v-model="filter" name="filter" id="filter" md-dense>
                  <md-option v-for="item in filters" :key="item.id" :value="item.id">{{ item.description }}</md-option>
                </md-select>
              </md-field>
            </div>
            <div style="max-width: 110px;">
              <md-field>
                <label for="filter">Refresh</label>
                <md-select v-model="refresh" name="filter" id="filter" md-dense>
                  <md-option v-for="item in refreshList" :key="item.id" :value="item.id">{{ item.description }}</md-option>
                </md-select>
              </md-field>
              <!-- <md-field>
                <label>Refresh (min)</label>
                <md-input style="max-width: 80px;" v-model="refresh" type="number"></md-input>
              </md-field> -->
              <!-- <div class="md-field md-theme-default md-has-value">
                <label for="md-input-q2irzkvf">Refresh (min)</label>
                <input type="number" class="md-input" style="max-width: 80px;" v-model="refresh" min="0">
              </div> -->
            </div>
          </div>
        </md-content>
      </div>
      <div style="width:100%;margin-top: 10px;height:100%;">
        <grid-layout
          :layout.sync="layout"
          :col-num="12"
          :row-height="30"
          :is-draggable="draggable"
          :is-resizable="resizable"
          :responsive="responsive"
          :vertical-compact="true"
          :use-css-transforms="true"
        >
          <grid-item v-for="(item, key) in layout"
              :key="key + serialize(item.i.url)"
              :static="item.static"
              :x="item.x"
              :y="item.y"
              :w="item.w"
              :h="item.h"
              :i="item.i"
          >
           <md-content class="md-elevation-1" v-if="layout.length > 0">
            <span class="md-subheading" style="float: left; padding: 10px;" v-if="item.i.title">{{ item.i.title }}</span>
            <vue-friendly-iframe v-if="item.i.type === 'apm'" :src="serialize(item.i.url)" @iframe-load="onLoad()" :className="item.i.class ? item.i.class : 'fullbox'" :ref="'if' + key"></vue-friendly-iframe>
           </md-content>
          </grid-item>
        </grid-layout>
      </div>
    </div>
  </div>
</template>

<script>
import { GridLayout, GridItem } from 'vue-grid-layout'
import Sidebar from '@/components/Sidebar.vue'
import { store } from '@customer-apps/styleguide'

export default {
  name: 'DashboardsComponents',
  props: {
    dt: {
      type: Object,
      required: true
    },
    list: {
      type: Array,
      required: false
    },
    hide: {
      type: Boolean,
      required: false
    }
  },
  components: {
    Sidebar,
    GridLayout,
    GridItem
  },
  watch: {
    dt: function (val) {
      this.layout = []
      this.$nextTick(() => {
        this.layout = this.dt.detail.layout
      })
      this.timeLimit()
    },
    filter: function (val) {
      this.$loading(true)
      this.layout = []
      this.$nextTick(() => {
        this.layout = this.dt.detail.layout
      })
      this.timeLimit()
    }
  },
  mounted () {
    this.$loading(true)
    this.layout = []
    this.$nextTick(() => {
      this.layout = this.dt.detail.layout
    })
    this.timeLimit()
  },
  created () {
    this.newRefresh()
  },
  data () {
    return {
      dns: '',
      item: '',
      layout: [],
      draggable: false,
      resizable: false,
      responsive: true,
      index: 0,
      filters: [
        {
          id: 0,
          description: 'Last Hour'
        },
        {
          id: 1,
          description: 'Last 4 Hours'
        },
        {
          id: 2,
          description: 'Last 8 Hours'
        },
        {
          id: 3,
          description: 'Last 24 Hours'
        },
        {
          id: 4,
          description: 'Last 7 Days'
        },
        {
          id: 5,
          description: 'Last 14 Days'
        },
        {
          id: 6,
          description: 'Last 30 Days'
        },
        {
          id: 7,
          description: 'Last 3 Months'
        },
        {
          id: 10,
          description: 'Yesterday'
        },
        {
          id: 11,
          description: 'Previous Week'
        },
        {
          id: 12,
          description: 'Previous Month'
        },
        {
          id: 14,
          description: 'Today'
        },
        {
          id: 15,
          description: 'Current Week'
        },
        {
          id: 16,
          description: 'Current Month'
        }
      ],
      refreshList: [
        {
          id: 1,
          description: '1 Minute'
        },
        {
          id: 5,
          description: '5 Minutes'
        },
        {
          id: 15,
          description: '15 Minutes'
        },
        {
          id: 30,
          description: '30 Minutes'
        },
        {
          id: 9999,
          description: 'Inactive'
        }
      ],
      filter: 1,
      refresh: 5
    }
  },
  methods: {
    selected (item) {
      this.item = item
      this.$emit('selected', item)
    },
    serialize (url) {
      var d = new Date().toString()
      url = url + '&d=' + d
      if (this.filter === '') {
        return url.replace('{token}', store.state.auth.access.valueOf()).replace('{filter}', 0)
      }
      return url.replace('{token}', store.state.auth.access.valueOf()).replace('{filter}', this.filter)
    },
    timeLimit () {
      this.$loading(true)
      setTimeout(() => {
        this.$loading(false)
      }, 2000)
    },
    newRefresh () {
      // this.$refs.if0.setAttribute('sandbox', this.sandbox)
      console.log(this.refresh)
      const min = (Number(this.refresh) * 60) * 1000
      this.$loading(true)
      this.layout = []
      this.$nextTick(() => {
        this.layout = this.dt.detail.layout
        this.$loading(true)
        setTimeout(() => {
          this.$loading(false)
        }, 2000)
      })
      setTimeout(() => {
        this.newRefresh()
      }, min)
    },
    onLoad () {
      // this.$loading(false)
    },
    filterInput (event) {
      this.refresh = Number(this.refresh)
    }
  }
}
</script>

<style lang="scss">
.fullscreen {
  padding: 0px !important;
  margin: 0px !important;
  width: calc(100vw - 20px);
  // height: calc(100vh - 85px) !important;
}
.fullscreen-with-list {
  padding: 0px !important;
  margin: 0px !important;
  width: calc(100vw - 20px);
  height: calc(100vh - 85px) !important;
}
#apm-dashboard {
  display: flex;
  flex-direction: row;
  background-color: #fff !important;
}
.apm-dashboard-sidebar {
  width: 200px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
input[type=number] {
  -moz-appearance: textfield !important;
}
</style>
