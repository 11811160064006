<template>
  <div class="apm-home">
    <AeBreadcrumbs
      :data="breadcrumbs" />
    <div class="reporting-list" v-if="multiple && Object.keys(selected).length === 0">
      <ae-my-app v-for="item in dashboards" :key="item.id" :data="item" :model="'clickable'" @selected="change($event)"></ae-my-app>
    </div>
    <div class="hidden-menu">
      <md-button v-if="selected.name && Object.keys(selected).length > 0" class="md-icon-button md-raised md-primary" @click="hideMe()">
        <md-icon v-if="hideMenu">keyboard_arrow_right</md-icon>
        <md-icon v-if="!hideMenu">keyboard_arrow_left</md-icon>
      </md-button>
    </div>
    <dashboards v-if="selected.name" :dt="selected" :list="dashboards" :hide="hideMenu" @selected="change($event)"></dashboards>
  </div>
</template>

<script>
import { AeMyApp, AeBreadcrumbs } from '@customer-apps/styleguide'
import Apm from '@/api/services/Apm.js'
import Dashboards from '@/components/Dashboards.vue'

export default {
  name: 'HomeView',
  components: {
    AeBreadcrumbs,
    AeMyApp,
    Dashboards
  },
  data () {
    return {
      breadcrumbs: [
        {
          link: '/',
          label: 'Home'
        },
        {
          link: '/app/apm',
          label: 'Advanced Performance Metrics'
        }
      ],
      layout: [],
      draggable: false,
      resizable: false,
      responsive: true,
      index: 0,
      multiple: false,
      dashboards: [],
      url: '',
      selected: {},
      hideMenu: false
    }
  },
  watch: {
    dashboards: function (val) {
      if (val.length > 1) {
        this.multiple = true
      } else {
        this.multiple = false
        this.selected = val[0]
      }
    },
    selected: function (val) {
      if (Object.keys(val).length === 0 || this.dashboards.length < 2) {
        this.breadcrumbs = [
          {
            link: '/',
            label: 'Home'
          },
          {
            link: '/app/apm',
            label: 'Advanced Performance Metrics'
          }
        ]
      } else {
        this.breadcrumbs = [
          {
            link: '/',
            label: 'Home'
          },
          {
            link: '/app/apm',
            label: 'Advanced Performance Metrics'
          },
          {
            link: '#',
            label: val.name
          }
        ]
      }
    }
  },
  methods: {
    change (item) {
      this.$loading(true)
      this.selected = item
      setTimeout(() => {
        this.$loading(false)
      }, 500)
    },
    onLoad (item) {
      console.log(item)
    },
    hideMe () {
      if (this.hideMenu) {
        this.hideMenu = false
      } else {
        this.hideMenu = true
      }
    }
  },
  created () {
    this.$loading(true)
    Apm.list().then(response => {
      response.data.results[0].dashboard.forEach(item => {
        this.dashboards.push({
          id: item.id,
          name: item.description,
          icon: 'reporting',
          base: item.description,
          detail: item.detail
        })
      })
      this.$loading(false)
    }).catch(() => {
      this.$loading(false)
    })
  }
}
</script>

<style>
.fullbox {
  padding: 0px !important;
  margin: 0px;
  width: 100% !important;
  height: 350px !important;
  overflow: hidden !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
.fullbox-2 {
  padding: 0px !important;
  margin: 0px;
  width: 100% !important;
  height: 390px !important;
  overflow: hidden !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.fullbox-2::-webkit-scrollbar {
  display: none;
}
.fullbox::-webkit-scrollbar {
  display: none;
}
.hidden-menu {
  position: fixed;
  bottom: 5px;
  left: 0;
  z-index: 99999;
}
</style>
