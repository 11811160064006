import axios from 'axios'
import { store } from '@customer-apps/styleguide'

import * as Constants from '@/constants'

import { asyncLoading } from 'vuejs-loading-plugin'

export let cancelToken = null
export var cancel = () => {
  if (cancelToken) {
    cancelToken.cancel()
  }
}

export const http = (auth, base = Constants.API_BASEURL) => {
  var requester = axios.create({
    baseURL: base
  })

  if (auth) {
    requester.defaults.headers.Authorization = 'Bearer ' + store.state.auth.access
  }

  // refresh token
  // let failedQueue = []

  // const processQueue = (error, token = null) => {
  //   failedQueue.forEach(prom => {
  //     if (error) {
  //       prom.reject(error)
  //     } else {
  //       prom.resolve(token)
  //     }
  //   })
  //   failedQueue = []
  // }
  // end refresh

  cancel()

  // Add a request interceptor
  requester.interceptors.request.use(config => {
    cancelToken = axios.CancelToken.source()
    config.cancelToken = cancelToken.token
    return config
  }, error => {
    return Promise.reject(error)
  })

  // Add a response interceptor
  requester.interceptors.response.use((response) => {
    // Do something with response data
    var result = Promise.resolve(response)
    return asyncLoading(result).then().catch()
  }, (error) => {
    switch (error.response.status) {
      case 401:
        store.commit('resetState')
        window.location.pathname = '/'
        // var originalRequest = error.config
        // originalRequest._retry = true
        // var refreshToken = {
        //   refresh: store.state.auth.refresh
        // }

        // if (error.config.url === '/auth/token/refresh/' || !store.state.auth.refresh) {
        //   store.commit('resetState')
        //   var result = Promise.reject(error)
        //   return asyncLoading(result).then().catch()
        // }

        // var resultRefresher = new Promise(function (resolve, reject) {
        //   requester.post('/auth/token/refresh/', refreshToken).then((response) => {
        //     store.commit('setLogin', response.data)
        //     requester.defaults.headers.common.Authorization = 'Bearer ' + store.state.auth.access
        //     originalRequest.headers.Authorization = 'Bearer ' + store.state.auth.access
        //     processQueue(null, store.state.auth.access)
        //     resolve(requester(originalRequest))
        //   }).catch((error) => {
        //     processQueue(error, null)
        //     window.location.pathname = '/'
        //     var r = Promise.reject(error)
        //     return asyncLoading(r).then().catch()
        //   })
        // })
        return {}
      default:
        var r = Promise.reject(error)
        return asyncLoading(r).then().catch()
    }
  })
  return requester
}

export default {
  http,
  cancel
}
